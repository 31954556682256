// base react
import React, { useState, useEffect } from "react";

import * as ElasticAppSearch from "@elastic/app-search-javascript";

// helper functions to configure search
import {
  getConfig,
} from "../../config/config-helper";

// extended styling
import '../../index.css';
import Highlighter from 'react-highlight-words';

// configure search
const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();

const client = ElasticAppSearch.createClient({
    hostIdentifier,
    searchKey,
    engineName,
    endpointBase,
});

const FullTranscript = (props) => {

  const [allTranscript, setAllTranscript] = useState([])

  useEffect(() => {

      const videoUrl = props.resultdata.video_url.raw
      const allTrans = []
      const options = {
        filters: { video_url: videoUrl, },
        sort: { "segment": "asc" },
        result_fields: { transcript: { raw: {} }, timestamp_url: { raw: {} } , segment_start: { raw: {} } },
        page: { size: 500 }
      };
      
      client
          .search("", options)
          .then(resultList => {
              resultList.results.forEach(result => {
              const oneTranscript = {}
              oneTranscript.transcript = result.getRaw("transcript")
              oneTranscript.timestamp = result.getRaw("segment_start")
              oneTranscript.url = result.getRaw("timestamp_url")
              allTrans.push(oneTranscript)
              });
              setAllTranscript(allTrans)
          })
          .catch(error => {
              console.log(`error: ${error}`);
          });
      
  }, [props.resultdata.video_url.raw])

  return (
    <div className="FullTranscript">
      { allTranscript.length === 0 ? (
        <div>Sorry, full transcript is not available</div>
      ):(
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full table-auto text-sm text-left text-gray-500 dark:text-gray-400">
          { props.resultdata.timestamp_url.raw.includes("youtube") ? (
            <caption class="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
              <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400"><b>Tip:</b> click on the timestamp to view the corresponding video section</p>
            </caption>
          ):(null)
          }
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3">Timestamp</th>
                <th scope="col" class="px-6 py-3">Transcript</th>
              </tr>
            </thead>
            <tbody>
              { allTranscript.map((item, index) => {
                return (
                    <tr key={ index } class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      { props.resultdata.timestamp_url.raw.includes("youtube") ? (
                        <td class="px-6 py-4"><a href={ item.url } className="ml-2 font-regular text-s text-theme-hyperlink" target="_blank" rel="noopener noreferrer">{ item.timestamp }</a></td>
                      ):(
                        <td class="px-6 py-4">{ item.timestamp }</td>
                      )
                      }
                      { item.transcript === '0' ? (
                        <td class="px-6 py-4">no speech</td>
                        ):(
                          <td class="px-6 py-4">
                            <Highlighter
                              searchWords={props.searchTerm.replaceAll("\"","").split(",").map(s => s.trim())}
                              autoEscape={true}
                              textToHighlight={item.transcript}
                            />
                          </td>
                        )
                      }
                    </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        )
      }
    </div>
  )
};

export default FullTranscript;