import { useState, useEffect } from "react";
import { getCookie } from "../../getCookie";
const Profile = () => {
  const [editView, setEditView] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("cheryl@example.com");
  const [slack, setSlack] = useState("cheryl");
  const [frequency, setFrequency] = useState("Daily");
  const [channels, setChannels] = useState("Email");
  const [editEmail, setEditEmail] = useState(email);
  const [editSlack, setEditSlack] = useState(slack);
  const [editFrequency, setEditFrequency] = useState(frequency);
  const [editChannels, setEditChannels] = useState(channels);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [editDay, setEditDay] = useState([
    "Applied when you want to receive weekly alerts",
  ]);
  const [selectedHours, setSelectedHours] = useState("08");
  const [selectedMinutes] = useState("00");
  const [selectedAMPM, setSelectedAMPM] = useState("AM");
  // const [selectedTZ, setSelectedTZ] = useState("Timezone");
  const [editTime, setEditTime] = useState("8:00 AM");

  useEffect(() => {
    //need to add selectedTZ to this 
    setEditTime(selectedHours + ":" + selectedMinutes + " " + selectedAMPM);
  }, [selectedHours, selectedMinutes, selectedAMPM]);

  const saveChanges = () => {
    if (!editChannels) {
      setError(true);
    } else {
      setError(false);
      updateProfile();
    }
  };

  useEffect(() => {
    async function fetchData() {
      fetch("/user_auth/profiles/", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
          Authorization: "Token " + localStorage.getItem("auth-token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setEmail(data.email_account);
          setSlack(data.slack_account);
          setFrequency(data.frequency);
          setChannels(data.channel);
          setEditEmail(data.email_account);
          setEditSlack(data.slack_account);
          setEditFrequency(data.frequency);
          setEditChannels(data.channel);
          setEditDay(data.day);
          setEditTime(data.time);
        });
    }
    fetchData();
  }, []);

  const updateProfile = async () => {
    setLoading(true);
    fetch("/user_auth/profiles/", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
        Authorization: "Token " + localStorage.getItem("auth-token"),
      },
      body: JSON.stringify({
        email_account: editEmail,
        slack_account: editSlack,
        frequency: editFrequency,
        channel: editChannels,
        day: editDay,
        time: editTime,
        user: localStorage.getItem("token"),
      }),
    }).then((res) => {
      if (res.status === 201) {
        setLoading(false);
        setEmail(editEmail);
        setSlack(editSlack);
        setFrequency(editFrequency);
        setChannels(editChannels);
        setEditDay(editDay);
        setEditTime(editTime);
        setSuccess(true);
        setEditView(false);
        successRemove();
      } else {
        console.log("failed");
      }
    });
  };

  const successRemove = () => {
    setInterval(() => {
      setSuccess(false);
    }, 3000);
  };

  return (
    <div>
      <div className="flex flex-col overflow-hidden bg-white sm:rounded-lg ml-16 md:ml-64 relative">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-base font-semibold leading-6 text-gray-900">
            Profile Information
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Personal details and application.
          </p>
        </div>
        {!editView ? (
          <table className="w-full text-sm text-left text-gray-500">
            <tbody>
              <tr className="bg-gray-50 bg-white border-b bg-white-200 border-gray-100">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Email Address
                </th>
                <td class="px-6 py-4">{email}</td>
                <td class="px-6 py-4"></td>
              </tr>
              {/*  for slack implementation */}
              {/* <tr className="bg-white border-b bg-white-200 border-gray-200">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Slack Account
                </th>
                <td class="px-6 py-4">{slack}</td>
                <td class="px-6 py-4"></td>
              </tr>
              <tr className="bg-gray-50 bg-white border-b bg-white-200 border-gray-100">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Channel you want to receive your alert
                </th>
                <td class="px-6 py-4">{channels}</td>
                <td class="px-6 py-4"></td>
              </tr> */}
              <tr className="bg-white bg-white border-b bg-white-200 border-gray-100">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Frequency
                </th>
                <td class="px-6 py-4">{frequency}</td>
                <td class="px-6 py-4"></td>
              </tr>
              {frequency === "Weekly" && (
                <tr className=" bg-gray-50 bg-white border-b bg-white-200 border-gray-100">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    Day for weekly alert
                  </th>
                  <td class="px-6 py-4">{editDay}</td>
                  <td class="px-6 py-4"></td>
                </tr>
              )}
              <tr className="bg-white border-b bg-white-200 border-gray-100">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Time you want to receive your alert
                </th>
                <td class="px-6 py-4">{editTime}</td>
                <td class="px-6 py-4"></td>
              </tr>
            </tbody>
          </table>
        ) : (
          <form>
            <table className="w-full text-sm text-left text-white-200 border-gray-200">
              <tbody>
                <tr className="bg-white border-b bg-white-200 border-gray-200">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    Email Address
                  </th>
                  <td class="px-6 py-4">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={editEmail}
                      className="border border-gray-300 rounded-md py-2 px-3 "
                      onChange={(e) => {
                        setEditEmail(e.target.value);
                      }}
                    />
                  </td>
                </tr>
                {/* <tr className="bg-white border-b bg-white-200 border-gray-100">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    Slack Account
                  </th>
                  <td class="px-6 py-4">
                    <input
                      type="text"
                      name="slack"
                      id="slack"
                      value={editSlack}
                      className="border border-gray-300 rounded-md py-2 px-3"
                      onChange={(e) => {
                        setEditSlack(e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr className="bg-white border-b bg-white-200 border-gray-100">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    Set the channel you want to receive the alerts
                  </th>
                  <td class="px-6 py-4">
                    <select
                      id="channels"
                      value={editChannels}
                      onChange={(e) => setEditChannels(e.target.value)}
                      class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 px-6"
                    >
                      <option selected>Choose a channel</option>
                      <option value="Email">Email</option>
                      <option value="Slack">Slack</option>
                    </select>
                  </td>
                  <td class="px-6 py-4"></td>
                </tr> */}
                <tr className="bg-white border-b bg-white-200 border-gray-100">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    Frequency
                  </th>
                  <td class="px-6 py-4">
                    <select
                      name="frequency"
                      id="frequency"
                      value={editFrequency}
                      onChange={(e) => {
                        const selectedFrequency = e.target.value;
                        setEditFrequency(selectedFrequency); // Update frequency first
                    
                        // If the selected frequency is "Daily", set the day to null; otherwise, keep the existing day value.
                        setEditDay(selectedFrequency === "Daily" ? null : editDay);
                      }}
                      class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 px-6"
                    >
                      <option selected>Choose frequency</option>
                      <option value="Daily">Daily</option>
                      <option value="Weekly">Weekly</option>
                    </select>
                  </td>
                  <td class="px-6 py-4"></td>
                </tr>

                {editFrequency === "Weekly" && (
                  <tr className="bg-white border-b bg-white-200 border-gray-100">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      Day for weekly alert
                    </th>
                    <td class="px-6 py-4">
                      <select
                        name="day"
                        id="day"
                        value={editDay}
                        onChange={(e) => setEditDay(e.target.value)}
                        class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 px-6"
                        placeholder="Select a day if you choose to receive the alert weekly"
                      >
                        <option value="Monday">Monday</option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thursday">Thursday</option>
                        <option value="Friday">Friday</option>
                        <option value="Saturday">Saturday</option>
                        <option value="Sunday">Sunday</option>
                      </select>
                    </td>
                    <td class="px-6 py-4"></td>
                  </tr>
                )}
                <tr className="bg-white border-b bg-white-200 border-gray-100">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    Time you want to receive your alert
                    <div className="mt-1 max-w-2xl text-sm text-gray-500">
                        Note: Alerts sent out in UTC time zone. <br></br>To convert: + 4 hours for EST, + 5 for CST,  + 6 for MST and  + 7 for PST.
                    </div>
                  </th>
                  <td class="px-6 py-4">
                    <div class="px-5 py-2 w-40 bg-white  border-gray-200 border-b">
                      <div class="flex">
                        <select
                          name="hours"
                          class="bg-transparent text-medium appearance-none outline-none"
                          value={selectedHours}
                          onChange={(e) => setSelectedHours(e.target.value)}
                        >
                          {[...Array(11).keys()].map((i) => (
                            <option value={i + 1}>{i + 1}:00</option>
                          ))}
                        </select>
                        <select
                          name="ampm"
                          class="bg-transparent text-medium appearance-none outline-none"
                          onChange={(e) => setSelectedAMPM(e.target.value)}
                          value={selectedAMPM}
                        >
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </select>
                        <div class="px-5 py-2 text-medium">UTC</div>
                        {/* Timezone option */}
                        {/* <select
                          name="tz"
                          class="bg-transparent text-medium appearance-none outline-none"
                          onChange={(e) => setSelectedTZ(e.target.value)}
                          value={selectedTZ}
                        >
                          <option value="EST">EST</option>
                          <option value="CTRL">CTRL</option>
                          <option value="MTN">MTN</option>
                          <option value="PST">PST</option>
                        </select> */}
                      </div>

                    </div>
                  </td>
                  <td class="px-6 py-4"></td>
                </tr>
              </tbody>
            </table>
          </form>
        )}
      </div>

      <div className="overflow-hidden bg-white sm:rounded-lg ml-64 relative">
        <div className=" overflow-hidden flex justify-left  px-4 py-5 sm:px-6">
          {!editView ? (
            <>
              <button
                className="bg-theme-blue items-center justify-center rounded-md border border-transparent py-2 px-6 bg-blue-500 text-base font-medium text-white"
                onClick={() => {
                  setEditView(true);
                  setLoading(false);
                }}
              >
                Edit
              </button>
              {success && (
                <p className="items-center justify-center py-3 px-5">
                  Changes saved!
                </p>
              )}
            </>
          ) : (
            <>
              <button
                className="bg-theme-blue items-center justify-center rounded-md border border-transparent py-2 px-5 bg-blue-500 text-base font-medium text-white"
                onClick={saveChanges}
                disabled={loading}
              >
                {loading ? "Saving..." : "Save"}
              </button>
              <button
                className="items-center justify-center rounded-md border border-transparent py-2 px-5 bg-white-300 text-base font-medium text-black"
                onClick={() => {
                  setEditView(false);
                }}
              >
                Cancel
              </button>
              {error && (
                <p className="items-center justify-center py-3 px-5">
                  Please select a default channel
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
